<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "default",
  components: {},
  data() {
    return {
      userdata: null,
    };
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
  },
  computed: {},
  beforeCreate() {},
};
</script>
